<template>
  <div class="container mt-5 px-2">
    <article class="message is-danger" v-if="!token">
      <div class="message-body">
        You have not signed in yet. Please go back to <a href="/">home page</a> to sign in first.
      </div>
    </article>
    <div v-if="token">
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><router-link :to="'/products'">Products</router-link></li>
          <li>
            <a href="#" aria-current="page" @click="showDetails = !showDetails">
              <span>{{productId}}</span> &nbsp;-&nbsp;
              <span>{{product && product.model}}</span>
              <span class="icon">
                <i class="fas" :class="{'fa-chevron-up': showDetails, 'fa-chevron-down': !showDetails}"></i>
              </span>
            </a>
          </li>
        </ul>
      </nav>
      <div class="mt-3">
        <div v-if="waiting || !product">
          <span class="icon is-medium is-size-4">
            <i class="fas fa-spinner fa-pulse"></i>
          </span>
        </div>
        <div v-else>
          <div v-show="showDetails">
            <div class="field mt-3">
              <label class="label">Name</label>
              <div class="control">
                <input class="input" type="text" v-model="product.name" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Model</label>
              <div class="control">
                <input class="input" type="text" v-model="product.model" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Brand</label>
              <div class="control">
                <input class="input" type="text" v-model="product.brand" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">NS Sku</label>
              <div class="control">
                <input class="input" type="text" v-model="product.nsSku" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">NS Id Index</label>
              <div class="control">
                <input class="input" type="text" v-model="product.nsIdIndex" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">NS Name</label>
              <div class="control">
                <input class="input" type="text" v-model="product.nsName" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Class</label>
              <div class="control">
                <input class="input" type="text" v-model="product.productClass" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Overstock Sku</label>
              <div class="control">
                <input class="input" type="text" v-model="product.overstockSku" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Overstock Option Id</label>
              <div class="control">
                <input class="input" type="text" v-model="product.overstockOptionId" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Amazon ASIN</label>
              <div class="control">
                <input class="input" type="text" v-model="product.amazonAsin" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Lowes Item Id</label>
              <div class="control">
                <input class="input" type="text" v-model="product.lowesItemId" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">
                <span>Competitors</span>
                <a v-for="(cl, i) in competitorLinks" :key="'cl-' + i" :href="cl.link">&nbsp;{{cl.id}}</a>
              </label>
              <div class="control">
                <input class="input" type="text" v-model="product.competitorIds" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Landed Cost Params</label>
              <div class="control">
                <input class="input" type="text" v-model="product.landedCostParams" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Forecast Params</label>
              <div class="control">
                <input class="input" type="text" v-model="product.forecastParams" readonly>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Wayfair Data</label>
              <div class="control">
                <textarea class="textarea" v-model="product.wayfairData" readonly></textarea>
              </div>
            </div>
            <div class="field mt-3">
              <label class="label">Home Depot Data</label>
              <div class="control">
                <textarea class="textarea" v-model="product.homeDepotData" readonly></textarea>
              </div>
            </div>
          </div>

          <div>
            <div>
              <h1 class="title is-5 mt-6">Price Chart</h1>
            </div>
            <div class="columns">
              <div class="column">
                <div class="chart-container">
                  <v-chart autoresize :option="pricesChartOption" :ref="'price-chart'" />
                </div>
              </div>
              <div class="column chart-container">
                <div class="chart-container">
                  <v-chart autoresize :option="inventoriesChartOption" :ref="'inventory-chart'" />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <a class="button is-pulled-right" :class="{'is-loading': exporting}" @click="exportPrices">Export</a>
              <h1 class="title is-5 mt-6">Price Table</h1>
            </div>
            <table class="table is-fullwidth is-striped" v-if="true">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Date</th>
                  <th>
                    <span>NS Inventory</span>&nbsp;
                    <span class="is-size-7 has-text-grey">CA, NY, TX</span>
                  </th>
                  <th>
                    <span>Wholesale Price</span>&nbsp;
                    <span class="is-size-7 has-text-grey">Promotion</span>
                  </th>
                  <th v-for="(c, i) in channels" :key="'col-header-' + i">
                    {{c.channel.name}}
                  </th>
                  <th v-for="(cp, i) in competitorPrices" :key="'col-header-cp-' + i">
                    <a :href="'/product/' + cp.product.id">{{cp.product.model}}</a>&nbsp;
                    <span class="is-size-7 has-text-grey">{{cp.channel.name}}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(d, i) in showingDates" :key="'row-date-' + i">
                  <td>{{i + 1}}</td>
                  <td class="price-date">{{d}}</td>
                  <td>
                    <span v-if="datePriceMap[d]">
                      <span>{{datePriceMap[d].nsInventoryAmount}}</span>&nbsp;
                      <span class="is-size-7 has-text-grey" v-if="datePriceMap[d].nsInventoryAmount && (datePriceMap[d].nsCaInventoryAmount || datePriceMap[d].nsNyInventoryAmount || datePriceMap[d].nsTxInventoryAmount)">
                        {{datePriceMap[d].nsCaInventoryAmount + ', ' + datePriceMap[d].nsNyInventoryAmount + ', ' + datePriceMap[d].nsTxInventoryAmount}}
                      </span>
                    </span>
                  </td>
                  <td>
                    <span v-if="wholesalePriceMap[d]">
                      <span>{{wholesalePriceMap[d].priceLabel}}</span>
                      <span class="is-size-7 has-text-grey" v-if="promotionMap[d]">
                        {{promotionMap[d].priceLabel}}
                      </span>
                    </span>
                  </td>
                  <td v-for="(c, j) in channels" :key="'row-' + i + '-' + j">
                    <span v-if="datePriceMap[d]">
                      <span v-if="datePriceMap[d][c.channel.id]">
                        <span>{{datePriceMap[d][c.channel.id].priceLabel}}</span>&nbsp;
                        <span v-if="datePriceMap[d][c.channel.id].latestChange.startsWith(datePriceMap[d][c.channel.id].date)">
                          <span :class="{'has-text-success': datePriceMap[d][c.channel.id].latestChange.endsWith(',-'), 'has-text-danger': datePriceMap[d][c.channel.id].latestChange.endsWith(',+')}">
                            <i class="fas" :class="{'fa-arrow-up': datePriceMap[d][c.channel.id].latestChange.endsWith(',+'), 'fa-arrow-down': datePriceMap[d][c.channel.id].latestChange.endsWith(',-')}"></i>
                          </span>
                        </span>&nbsp;
                        <span class="tag is-danger" v-if="datePriceMap[d][c.channel.id].flag && datePriceMap[d][c.channel.id].flag.includes('Out of Stock')">Out of Stock</span>&nbsp;
                        <span class="tag is-warning" v-if="datePriceMap[d][c.channel.id].flag && datePriceMap[d][c.channel.id].flag.includes('Price Hidden')">Price Hidden</span>&nbsp;
                        <span class="tag is-success" v-if="datePriceMap[d][c.channel.id].availableAmount">{{datePriceMap[d][c.channel.id].availableAmount}}</span>
                      </span>
                    </span>
                  </td>
                  <td v-for="(cp, j) in competitorPrices" :key="'row-cp-' + i + '-' + j">
                    <span v-if="cp.priceMap[d]">
                      {{cp.priceMap[d].priceLabel}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <a class="button" @click="showAll = !showAll">Toggle all (might take long time to show all)</a>
          </div>
        </div>
      </div>
      <div v-if="error" class="notification is-danger is-light">
        <button class="delete" @click="error=''"></button>
        {{error}}
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from 'dateformat'
import { saveAs } from 'file-saver'
import { urlToHttpOptions } from 'url'


export default {
  name: 'Product',
  data () {
    return {
      error: '',
      waiting: false,
      showDetails: false,
      product: null,
      channels: null,
      latestPrices: [],
      exporting: false,
      commonOption: {
        legend: {
          data: ['Wholesale', 'Home Depot', 'Wayfair', 'Overstock', 'Amazon', 'Lowes'],
          top: 30
        },
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {}
          }
        },
        yAxis: {
          type: 'value'
        }
      },
      competitors: [],
      wholesalePrices: [],
      promotions: [],
      showAll: false,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    token () {
      return this.$store.state.user.token
    },
    productId () {
      return this.$route.params.productId
    },
    channelMap () {
      if (!this.channels) {
        return {}
      }
      var channelMap = {}
      for (var channel of this.channels) {
        channelMap[channel.channel.id] = channel.channel.name
      }
      return channelMap
    },
    datePriceMap () {
      if (!this.product || !this.channels) {
        return []
      }
      var datePriceMap = {}
      for (var channel of this.channels) {
        for (var price of channel.prices) {
          price.priceLabel = '$' + price.price.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: false
          })
          var row = datePriceMap[price.date]
          if (row) {
            row[price.channelId] = price
            if (!row.nsInventoryAmount) {
              row.nsInventoryAmount = price.nsInventoryAmount
              row.nsCaInventoryAmount = price.nsCaInventoryAmount
              row.nsNyInventoryAmount = price.nsNyInventoryAmount
              row.nsTxInventoryAmount = price.nsTxInventoryAmount
            }
          } else {
            datePriceMap[price.date] = {}
            datePriceMap[price.date][price.channelId] = price
            datePriceMap[price.date].nsInventoryAmount = price.nsInventoryAmount
            datePriceMap[price.date].nsCaInventoryAmount = price.nsCaInventoryAmount
            datePriceMap[price.date].nsNyInventoryAmount = price.nsNyInventoryAmount
            datePriceMap[price.date].nsTxInventoryAmount = price.nsTxInventoryAmount
          }
        }
      }
      for (var price of this.latestPrices) {
        price.priceLabel = '$' + price.price.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: false
        })
        var row = datePriceMap[price.date]
        if (row) {
          row[price.channelId] = price
        } else {
          datePriceMap[price.date] = {}
          datePriceMap[price.date][price.channelId] = price
        }
      }
      return datePriceMap
    },
    dates () {
      if (!this.datePriceMap) {
        return []
      }
      var dates = Object.keys(this.datePriceMap)
      for (var cp of this.competitorPrices) {
        dates = dates.concat(Object.keys(cp.priceMap))
      }
      dates = Array.from(new Set(dates))
      return dates.sort((a, b) => b.localeCompare(a))
    },
    showingDates () {
      if (this.showAll) {
        return this.dates
      }
      return this.dates.slice(0, 10)
    },
    xAxis () {
      var option = {type: 'category'}
      if (!this.dates.length) {
        return option
      }
      var firstDate = new Date(this.dates[this.dates.length - 1])
      var lastDate = new Date(this.dates[0])
      var dates = []
      var date = firstDate
      while (date.getTime() <= lastDate.getTime()) {
        dates.push(date.toISOString().substring(0, 10))
        date = new Date(date.getTime() + 24 * 3600 * 1000)
      }
      option.data = dates
      return option
    },
    priceSeries () {
      var vm = this
      var channels = [
        {id: 1, name:'Home Depot'},
        {id: 2, name:'Wayfair'},
        {id: 3, name:'Overstock'},
        {id: 4, name:'Amazon'},
        {id: 5, name:'Lowes'},
      ]
      var maxPrice = null
      var minPrice = null
      var series = channels.map(c => {
        var prices = []
        var latestPrice = null
        if (vm.xAxis.data) {
          vm.xAxis.data.forEach((date, index) => {
            var ps = vm.datePriceMap[date]
            if (!ps) {
              prices.push(null)
            } else {
              var p = ps[c.id]
              if (!p) {
                prices.push(null)
              } else {
                prices.push(p.price)
                latestPrice = [index, p.price]
                if (maxPrice == null) {
                  maxPrice = p.price
                }
                if (minPrice == null) {
                  minPrice = p.price
                }
                if (p.price > maxPrice) {
                  maxPrice = p.price
                }
                if (p.price < minPrice) {
                  minPrice = p.price
                }
              }
            }
          })
        }
        var serie = {
          name: c.name,
          data: prices,
          type: 'line'
        }
        if (latestPrice) {
          serie.markPoint = {
            data: [
              { coord: latestPrice, value: latestPrice[1], symbolSize: 0, symbolOffset: [30, 0], label: {color: 'inherit'}, emphasis: {disabled: true} }
            ]
          }
        }
        return serie
      })

      var wholesales = []
      var latestWholesale = null
      if (vm.xAxis.data) {
        vm.xAxis.data.forEach((date, index) => {
          var wholesalePrice = vm.wholesalePriceMap[date]
          if (!wholesalePrice) {
            wholesales.push(null)
          } else {
            var value = wholesalePrice.price
            var promotion = vm.promotionMap[date]
            if (promotion) {
              value = value * (1 - (promotion.percentage / 100))
            }
            wholesales.push(value)
            latestWholesale = [index, value]
            if (maxPrice == null) {
              maxPrice = value
            }
            if (minPrice == null) {
              minPrice = value
            }
            if (value > maxPrice) {
              maxPrice = value
            }
            if (value < minPrice) {
              minPrice = value
            }
          }
        })
      }
      var wholesaleSerie = {
        name: 'Wholesale',
        data: wholesales,
        type: 'line'
      }
      if (latestWholesale) {
        wholesaleSerie.markPoint = {
          data: [
            { coord: latestWholesale, value: latestWholesale[1], symbolSize: 0, symbolOffset: [30, 0], label: {color: 'inherit'}, emphasis: {disabled: true} }
          ]
        }
      }
      series.unshift(wholesaleSerie)

      var yAxis = {
          type: 'value'
      }
      if (maxPrice && minPrice && maxPrice != minPrice) {
        var range = maxPrice - minPrice
        yAxis.max = Math.ceil((maxPrice + (range * 0.15)) / 100) * 100
        yAxis.min = Math.floor((minPrice - (range * 0.15)) / 100) * 100
      }
      return [series, yAxis]
    },
    pricesChartOption () {
      var option = {...this.commonOption}
      option.title = {text: 'Prices', left: 'center'}
      option.xAxis = this.xAxis
      option.series = this.priceSeries[0]
      option.yAxis = this.priceSeries[1]
      option.dataZoom = [
        {start: 70, end: 100},
        {type: 'inside'}
      ]
      return option
    },
    inventorySeries () {
      var vm = this
      var channels = [
        {id: 1, name:'Home Depot'},
        {id: 2, name:'Wayfair'},
        {id: 3, name:'Overstock'},
        {id: 4, name:'Amazon'},
        {id: 5, name:'Lowes'},
        {id: 0, name:'NetSuite'},
      ]
      return channels.map(c => {
        var values = []
        var latestOne = null
        if (vm.xAxis.data) {
          vm.xAxis.data.forEach((date, index) => {
            var ps = vm.datePriceMap[date]
            if (!ps) {
              values.push(null)
            } else {
              if (c.id == 0) {
                if (ps.nsInventoryAmount == null || ps.nsInventoryAmount == undefined) {
                  values.push(null)
                } else {
                  values.push(ps.nsInventoryAmount)
                  latestOne = [index, ps.nsInventoryAmount]
                }
              } else {
                var p = ps[c.id]
                if (!p) {
                  values.push(null)
                } else {
                  values.push(p.availableAmount)
                  latestOne = [index, p.availableAmount]
                }
              }
            }
          })      
        }
        var serie = {
          name: c.name,
          data: values,
          type: 'line'
        }
        if (latestOne) {
          serie.markPoint = {
            data: [
              { coord: latestOne, value: latestOne[1], symbolSize: 0, symbolOffset: [15, 0], label: {color: 'inherit'}, emphasis: {disabled: true} }
            ]
          }
        }
        return serie
      })
    },
    inventoriesChartOption () {
      var option = {...this.commonOption}
      option.legend = {
        data: ['NetSuite', 'Home Depot', 'Wayfair', 'Overstock', 'Amazon', 'Lowes'],
        top: 30
      }
      option.title = {text: 'Inventories', left: 'center'}
      option.xAxis = this.xAxis
      option.series = this.inventorySeries
      option.dataZoom = [
        {start: 70, end: 100},
        {type: 'inside'}
      ]
      return option
    },
    competitorLinks () {
      if (!this.product) {
        return []
      }
      var competitorIds = []
      if (this.product.competitorIds) {
        competitorIds = this.product.competitorIds.split(/,|;/).map(i => i.trim()).filter(i => i)
      }
      return competitorIds.map(id => ({id: id, link: '/product/' + id}))
    },
    competitorPrices () {
      var columns = []
      for (var competitor of this.competitors) {
        for (var channel of competitor.channels) {
          if (channel.prices && channel.prices.length) {
            var priceMap = {}
            for (var price of channel.prices) {
              price.priceLabel = '$' + price.price.toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: false
              })
              priceMap[price.date] = price
            }
            columns.push({
              product: competitor.product,
              channel: channel.channel,
              priceMap: priceMap,
            })
          }
        }
      }
      return columns
    },
    wholesalePriceMap () {
      if (!this.dates || !this.wholesalePrices.length) {
        return {}
      }
      var wholesalePriceMap = {}
      for (var date of this.dates) {
        for (var wholesale of this.wholesalePrices) {
          if (wholesale.endDate) {
            if (date >= wholesale.startDate && date <= wholesale.endDate) {
              wholesalePriceMap[date] = wholesale
            }
          } else {
            if (date >= wholesale.startDate) {
              wholesalePriceMap[date] = wholesale
            }
          }
        }
      }
      return wholesalePriceMap
    },
    promotionMap () {
      if (!this.dates) {
        return {}
      }
      var promotionMap = {}
      for (var date of this.dates) {
        var wholesalePrice = this.wholesalePriceMap[date]
        if (wholesalePrice) {
          var activePromotion = null
          for (var promotion of this.promotions) {
            if (date >= promotion.startDate && date <= promotion.endDate) {
              if (!activePromotion || activePromotion.percentage < promotion.percentage) {
                activePromotion = promotion
                var p = JSON.parse(JSON.stringify(promotion))
                p.price = wholesalePrice.price * (1 - (p.percentage / 100))
                p.priceLabel = '$' + p.price.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                  useGrouping: false
                })
                promotionMap[date] = p
              }
            }
          }
        }
      }
      return promotionMap
    },
  },
  methods: {
    getProduct () {
      this.waiting = true
      this.$http.get(this.server + '/myapp/get-product-with-prices/' + this.productId + '/').then(resp => {
        this.product = resp.body.product
        this.channels = resp.body.channels
        /*
        for (const channel of this.channels) {
          this.updateLatestPrice(channel.channel)
        }*/
        this.getCompetitors()
        this.waiting = false
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    getCompetitors () {
      var competitorIds = []
      if (this.product.competitorIds) {
        competitorIds = this.product.competitorIds.split(/,|;/).map(i => i.trim()).filter(i => i)
      }
      for (var competitorId of competitorIds) {
        this.$http.get(this.server + '/myapp/get-product-with-prices/' + competitorId + '/').then(resp => {
          this.competitors.push(resp.body)
        }, err => {
          this.error = err.body
        })
      }
    },
    updateLatestPrice (channel) {
      this.$http.post(this.server + '/myapp/update-product-latest-price/' + this.productId + '/' + channel.id + '/', {}).then(resp => {
        this.latestPrices.push(resp.body)
      }, err => {
        this.error = err.body
      })
    },
    exportPrices () {
      if (this.exporting) {
        return
      }
      this.exporting = true
      var headers = ['Date', 'NS Inventory', 'NS CA', 'NS NY', 'NS TX', 'HD Price', 'HD Amount', 'Wayfair Price', 'Wayfair Amount', 'Overstock Price', 'Overstock Amount', 'Amazon Price', 'Amazon Amount', 'Lowes Price', 'Lowes Amount']
      var rows = [headers.join(',')]
      for (const date of this.dates) {
        var row = [date]
        var ns_inventory = this.datePriceMap[date].nsInventoryAmount
        row.push((ns_inventory != null && ns_inventory != undefined) ? ns_inventory.toString() : '')
        var ns_ca = this.datePriceMap[date].nsCaInventoryAmount
        row.push((ns_ca != null && ns_ca != undefined) ? ns_ca.toString() : '')
        var ns_ny = this.datePriceMap[date].nsNyInventoryAmount
        row.push((ns_ny != null && ns_ny != undefined) ? ns_ny.toString() : '')
        var ns_tx = this.datePriceMap[date].nsTxInventoryAmount
        row.push((ns_tx != null && ns_tx != undefined) ? ns_tx.toString() : '')
        var hd_price = this.datePriceMap[date][1]
        row.push((hd_price && hd_price.price) ? hd_price.price.toString() : '')
        row.push((hd_price && hd_price.availableAmount) == null ? '' : hd_price.availableAmount.toString())
        var wayfair_price = this.datePriceMap[date][2]
        row.push((wayfair_price && wayfair_price.price) ? wayfair_price.price.toString() : '')
        row.push((wayfair_price && wayfair_price.availableAmount) == null ? '' : wayfair_price.availableAmount.toString())
        var overstock_price = this.datePriceMap[date][3]
        row.push((overstock_price && overstock_price.price) ? overstock_price.price.toString() : '')
        row.push((overstock_price && overstock_price.availableAmount) == null ? '' : overstock_price.availableAmount.toString())
        var amazon_price = this.datePriceMap[date][4]
        row.push((amazon_price && amazon_price.price) ? amazon_price.price.toString() : '')
        row.push((amazon_price && amazon_price.availableAmount) == null ? '' : amazon_price.availableAmount.toString())
        var lowes_price = this.datePriceMap[date][4]
        row.push((lowes_price && lowes_price.price) ? lowes_price.price.toString() : '')
        row.push((lowes_price && lowes_price.availableAmount) == null ? '' : lowes_price.availableAmount.toString())
        rows.push(row.join(','))
      }
      var string = rows.join('\r\n')
      var blob = new Blob([string], {type: 'text/plain;charset=utf-8'})
      saveAs(blob, this.productId.toString() + '.csv')
      this.exporting = false
    },
    getWholesalePrices(){
      this.$http.get(this.server + '/myapp/get-wholesale-prices-by-product/' + this.productId + '/').then(resp => {
        this.wholesalePrices = resp.body.wholesalePrices.map(wp => {
          wp.priceLabel = '$' + wp.price.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            useGrouping: false
          })
          return wp
        })
      }, err => {
        this.error = err.body
      })
    },
    getPromotions(){
      this.$http.get(this.server + '/myapp/get-promotions-by-product/' + this.productId + '/').then(resp => {
        this.promotions = resp.body.promotions
      }, err => {
        this.error = err.body
      })
    },
  },
  mounted () {
    this.$nextTick(function(){
      this.getProduct()
      this.getWholesalePrices()
      this.getPromotions()
    })
  },
}
</script>

<style scoped>
.price-date {
  white-space: nowrap;
}
.chart-container {
  height: 500px;
  width: 100%;
}
</style>
